/* eslint-disable */
import axios from 'axios'
import { URL } from './URL_SERVICIOS'
const API_URL = URL.PINCIPAL
const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json; charset=utf-8' }

class ChatService {

  obtenerIp() {
    return axios.get(API_URL + '/api/Chat/GetIPAddres');
  }

  ValidChat(id: string) {
    console.log("Url Peticion: " + API_URL + '/api/Chat/ValidChat?id=' + id);
    return axios.get(API_URL + '/api/Chat/ValidChat?id=' + id);
  }

  cargaInicial(aux: any) {
    return axios.post(API_URL + '/api/Chat/CargaInicialChat', aux, { headers: headers });
  }

  SaveChat(data: any) {
    console.log("Guardo conversación")
    data.mensaje = '';
    return axios.post(API_URL + '/api/Chat/SaveChatAsync', data, { headers: headers });
  }

  SaveChatComplement(chatCompleteEnc: any, dataSaveComplementChat: any) {
    var aux: any = { chat: chatCompleteEnc, mensaje: dataSaveComplementChat };
    axios.post(API_URL + '/api/Chat/SaveChatComplement', aux, { headers: headers });
  }

  UpdateChatTdd(aux: any) {
    axios.post(API_URL + '/api/Chat/UpdateChatTdd', { chat: aux, mensaje: '' }, { headers: headers });
  }

  SaveMesssage(data: any) {
    var url = localStorage.getItem('UrlBaseChat') + "/CreateConversationRecord"
    axios.post(url, JSON.stringify(data), { headers: headers });
  }

  FinishChatBot(data: any) {
    var url = API_URL + '/api/Chat/FinishChat';
    return axios.post(url, JSON.stringify(data), { headers: headers });
  }

  async SaveReportEvents(keyChat: any, idEvent: any) {
    var url = localStorage.getItem('UrlBaseChat') + "/SaveReportJourney"
    var data = { KeyChat: keyChat, EventId: idEvent };
    return await axios.post(url, JSON.stringify(data), { headers: headers });
  }

  async Inactividad() {
    var url = API_URL + '/api/Chat/Inactividad?mensaje=Inactivity'
    return await axios.post(url, {}, { headers: headers });
  }

  loadEmojis(data: any) {
    var url = localStorage.getItem('UrlBaseChat') + "/GetEmojis"
    return axios.post(url, JSON.stringify(data), { headers: headers });
  }

  getSentReceivedViewed(desc: any) {
    var url = localStorage.getItem('UrlGeneralApi') + "/api/CampaingMarketing/getSentReceivedViewed?desc=" + desc
    return axios.get(url, { headers: headers });
  }

  getCuestionario(isBot: any, keyChat: any, appType: any, sChannel: any) {
    var url = API_URL + "/api/Chat/Encuesta?guid=" + keyChat + "&appType=" + appType + "&channel=" + sChannel + "&isBot=" + isBot;
    return axios.get(url, { headers: headers });
  }

  SaveSurvey(data: any) {
    var url = API_URL + "/api/Chat/SaveSurvey";
    return axios.post(url, data, { headers: headers });
  }
  
  derivacion(requestDerivacion: any) {
    return axios.post(API_URL + '/api/Chat/Derivacion', requestDerivacion, { headers: headers });
  }

  InfoQualtrix(id: string) {
    return axios.get(API_URL + '/api/Chat/InfoQualtrix?keyChat=' + id);
  }
}
export default new ChatService()
